import {inject} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {Store} from '@ngxs/store';

export type GetByIdFn<T> = (id: string) => T;

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export function readStoreSignal<T>(selector: (state: any, ...states: any[]) => T) {
  const store = inject(Store);
  return toSignal(store.select(selector), {initialValue: store.selectSnapshot(selector)});
}
